<template>
  <div class="home">
    <div class="header">
      <span>发票信息</span>
    </div>
    <div class="nav">发票抬头</div>
    <ul class="content">
      <li>
        <span>企业名称</span>
        <div>{{ticketInfo.name}}</div>
      </li>
      <li>
        <span>纳税人类型</span>
        <div>一般纳税人</div>
      </li>
      <li>
        <span>纳税人识别号</span>
        <div>{{ticketInfo.creditCode}}</div>
      </li>
      <li>
        <span>注册地址</span>
        <div>{{ticketInfo.address}}</div>
      </li>
      <li>
        <span>开户行名称</span>
        <div>{{ticketInfo.bankName}}</div>
      </li>
      <li>
        <span>开户行账号</span>
        <div>{{ticketInfo.bankNo}}</div>
      </li>
      <li style="width:100%">
        <span style="width:136.93px">联系电话</span>
        <div>{{ticketInfo.phone}}</div>
      </li>
    </ul>
    <div class="nav">可开票内容</div>
    <div>
      <s-table :rowKey="(record) => record.id" :columns="columns" :data="dataLists" bordered :pagination="pagination">
      </s-table>
    </div>
    <div class="nav">邮寄信息</div>
    <ul class="content">
      <li>
        <span>姓名</span>
        <div><a-input class="ipt" placeholder='请输入姓名' v-model="mailedInfo.contactsName"></a-input></div>
      </li>
      <li>
        <span>电话</span>
        <div><a-input class="ipt" placeholder='请输入电话' v-model="mailedInfo.contactsPhone"></a-input></div>
      </li>
      <li style="width:100%">
        <span>地址</span>
        <div style="width:87.2%">
          <!-- <a-cascader class="area" :options="options" placeholder="请选择" @change="onChange" /> -->
          <a-input class="ipt" style="width:60%" placeholder='请输入详细地址' v-model="mailedInfo.contactsAddress"></a-input>
        </div>
      </li>
    </ul>
    <div class="opt">
      <a-button type="primary" @click="save">保存</a-button>
      <a-button style="margin-left:10px" @click="cancel">重置</a-button>
    </div>
  </div>
</template>

<script>
import STable from '@/components/table/';
import { USER_INFO } from "@/store/mutation-types";
import Vue from 'vue';
import {
  getSysRegion,
  getEnterpriseInvoiceInfo,
  getBusinessScopes,
  getDefBusinessScope,
  getInvoiceMailed,
  addInvoiceMailed
} from '@/api/api';
export default {
  name: 'Home',
  data () {
    return {
      options: [],
      userInfo: null,
      ticketInfo: '',
      ticketContent: [],
      scopedTicket: [],
      mailedInfo: {
        contactsName: '',
        contactsPhone: '',
        contactsAddress: ''
      },
      columns : [
        {
          title: '开票范围',
          dataIndex: 'businessScopeName',
        },
        {
          title: '开票税率(%)',
          dataIndex: 'taxRate',
          customRender: text => (text*100).toFixed(2)
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text === '100201'?'正常':'停用'
        },
      ],
      pagination: {
        hideOnSinglePage: true
      }
    }
  },
  components: {
    STable
  },
  created () {
    this.userInfo = Vue.ls.get(USER_INFO);
    this.getDefBusinessScope();
    this.getInvoiceMailed();
    this.getEnterpriseInvoiceInfos();
  },
  methods: {
    // 获取开票内容
    dataLists (parameter) {
      return getBusinessScopes(Object.assign(parameter,this.queryParams)).then(res => {
        this.ticketContent = res.result;
        res.result.data = res.result
        return res.result
      })
    },
    // 保存联系人信息
    save () {
      if (this.mailedInfo.contactsName==='') {
        this.$message.error('姓名不能为空')
        return
      } else if (!(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/).test(this.mailedInfo.contactsPhone)) {
        this.$message.error('手机号格式不正确')
        return
      } else if (this.mailedInfo.contactsAddress==='') {
        this.$message.error('地址不能为空')
        return
      }
      addInvoiceMailed(this.mailedInfo).then(res => {
        if (res.success) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 取消保存
    cancel () {
      this.getInvoiceMailed()
    },
    // 获取联系人信息
    getInvoiceMailed () {
      getInvoiceMailed().then(res => {
        if (res.success&&res.result) {
          this.mailedInfo = res.result;
        }
      })
    },
    // 获取默认开票内容
    getDefBusinessScope () {
      getDefBusinessScope().then(res => {
        if (res.success) {
          this.scopedTicket = res.result;
        }
      })
    },
    // 获取发票抬头信息
    getEnterpriseInvoiceInfos () {
      getEnterpriseInvoiceInfo().then(res => {
        console.log(res.result)
        if (res.success) {
          this.ticketInfo = res.result;
        }
      })
    },
    // 获取地区省份
    getSysRegion () {
      getSysRegion().then(res => {
        if (res.success) {
          this.options = res.result;
        }
      })
    },
    // 选择地区
    onChange(value) {
      console.log(value);
    },
  }
}
</script>
<style lang="less" scoped>
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    color: #283039;
    font-size: 18px;
    font-weight: bold;
  }
}
.nav{
  color: #283039;
  font-size: 14px;
  margin: 30px 0 18px 0;
}
.content{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  li{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
    color: #727682;
    span{
      width: 140px;
      height: 54px;
      background: #FAFAFA;
      padding: 20px 18px;
    }
    div{
      padding: 20px 18px;
      width: 320px;
      height: 54px;
      position: relative;
      .ipt{
        width: 80%;
        position: absolute;
        top: 12px;
        left: 20px;
      }
      .select{
        width:86%;
        position: absolute;
        top: -9px;
        left: 2px;
      }
      .date{
        position: absolute;
        top: -9px;
        left: 2px;
        width: 86%;
        height: 68px;
        background:rgba(0,0,0,0);
      }
    }
    .last-line{
      display: flex;
      align-items: center;
      position: relative;
      .area{
        position: absolute;
        top: -8px;
        left: 0px;
        width: 43%;
        background: rgba(0,0,0,0);
        height: 74px;
      }
      .iptt{
        width: 30%;
        position: absolute;
        top: 12px;
        right: 50px;
      }
    }
  }
}
.opt{
  text-align: center;
  margin: 30px 0 0 0;
}
</style>
<style>
.content .ant-calendar-picker-clear, .ant-calendar-picker-icon{
  right: 25px;
}
.content .ant-cascader-picker-arrow,.content .ant-cascader-picker-clear{
  right: 30px;
}
.content .ant-cascader-picker-label{
  left: 20px;
}
/* .ant-cascader-menus{
  top: 595px !important;
  left: 487px !important;
} */
</style>
